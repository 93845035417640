export const formatValue = (value, type) => {
  const exp = /^\w{0,3}\W?\s?(\d+)[.,](\d+)?,?(\d+)?$/g
  const replacer = (f, group1, group2, group3) => {
    return group3 ? `${group1}${group2}.${group3}` : `${group1}.${group2}`
  }

  let valueFormatted;
  switch(type) {
    case 'FormatDate':
      valueFormatted = new Intl.DateTimeFormat('pt-BR').format(value);
      break;
    case 'FormatMoneyReal':
      valueFormatted = new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
      break;
    case 'FormatDecimal':
      valueFormatted = new Intl.NumberFormat('pt-BR').format(value);
      break;
    case 'UndoFormat':
      valueFormatted = value.replace(exp, replacer);
      break;
    default:
      valueFormatted = undefined;
  }

  return valueFormatted;
}
