import React, { useState, useEffect } from 'react';

import { Grid/* , Button */ } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Report } from 'powerbi-report-component';

import { accountService, userService, reportService } from '../../services';


const useStyles = makeStyles((theme) => ({
	grid: {
		flexGrow: 1,
		paddingTop: 5,
	}/* ,
	button: {
		margin: theme.spacing(1),
		fontSize: 16,
		padding: '6px 12px',
		width: 200,
		lineHeight: 1.5,
	}, */
}));

function DetailDashboard() {
	const classes = useStyles();
	const [tokenData, setTokenData] = useState([]);
	//const [reportGrid, setReportGrid] = useState(null);
  const user = accountService.userValue;
  const [isLoaded, setIsLoaded] = useState();
  const [reportData, setReportData] = useState([]);

	const reportStyle = {
		height: '85vh'
	};

	const basicFilter = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Usuarios",
			column: "Usuario"
		},
		operator: "Is",
		values: [user.name],
		filterType: 1
	};

	const renderSettings = {
		filterPaneEnabled: false,
		//navContentPaneEnabled: false,
	};

/* 	function printReport() {
		if (report) report.print();
	} */

  async function loadUserReport() {
    try {
      if (reportData.reportID === undefined) {
        const { data } = await reportService.getUserReportID(user.id);

        setReportData(reportData.reportID = data[0].reportID);
        loadReport();
      }
    } catch (error) {
      setIsLoaded(false);
      // console.log("Erro ao pegar dados do usuário sobre o relatório: " + error);
    }
  }

  async function loadReport() {
    try {
      const { data } = await reportService.getReportID(reportData.reportID)

      setReportData(reportData.groupBI = data.groupBI,
                    reportData.reportBI = data.reportBI);
      loadEmdebToken();
    } catch (error) {
      setIsLoaded(false);
      // console.log("Erro ao pegar dados do relatório: " + error);
    }
  }

  async function loadEmdebToken() {
    try {
      const { data } = await userService.getEmbedTokenBI(reportData.groupBI, reportData.reportBI);
      data.embedUrl = 'https://app.powerbi.com/reportEmbed?reportId=' + reportData.reportBI +
            '&groupId=' + reportData.groupBI;
      data.reportId = reportData.reportBI;

      setTokenData(data);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(false);
      // console.log("Erro ao carregar os dados do BI: " + error);
    }
  }

	useEffect(() => {

    loadUserReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  if (isLoaded === undefined) {
    return <p>Carregando dados do relatório...</p>;
  }
	return isLoaded ? (
		<>
			<Grid height='100%'>
				<Grid item className={classes.grid}>
          <Report embedType="report"
            tokenType='AAD'
            accessToken={tokenData.embedToken}
            embedUrl={tokenData.embedUrl}
            embedId={tokenData.reportId}
            permissions='View'
            style={reportStyle}
            extraSettings={renderSettings}
            onLoad={(report) => {
              //setReportGrid(report);
              report.setFilters([basicFilter]).catch((errors) => {
                // console.log(errors);
              });
            }}
          />
				</Grid>
				{/* <Grid container justify="flex-end">
					<Button
						type="submit"
						color="primary"
						size="medium"
						variant="contained"
						className={classes.button}
						onClick={printReport}
					>Imprimir Relatório</Button>
				</Grid> */}
			</Grid>
		</>
	) : <p>Relatório do BI não encontrado! Favor, entrar em contato com o
  administrador do sistema para validar o cadastro do seu relatório do BI para o seu usuário.</p>;
}

export { DetailDashboard };
