import {
  getAllReports, getReportById, handleReportAdd, handleReportUpdate, handleReportDelete,
  getAllUsersReports, getUserReportById, handleUserReportAdd, handleUserReportUpdate, handleUserReportDelete,
} from '../helpers';

export const reportService = {
  getReports,
  getReportID,
  createReport,
  updateReport,
  deleteReport,
  getUsersReports,
  getUserReportID,
  createUserReport,
  updateUserReport,
  deleteUserReport,
};

// Reports
async function getReports() {
  const reports = await getAllReports();
  return reports;
};

async function getReportID(reportID) {
  const reports = await getReportById(reportID);
  return reports;
};

async function createReport(values) {
  const report = await handleReportAdd(values);
  return report;
};

async function updateReport(id, values) {
  const report = await handleReportUpdate(id, values);
  return report;
};

async function deleteReport(id) {
  const report = await handleReportDelete(id);
  return report;
};

// UsersReports
async function getUsersReports() {
  const usersReports = await getAllUsersReports();
  return usersReports;
};

async function getUserReportID(id) {
  const usersReports = await getUserReportById(id);
  return usersReports;
};

async function createUserReport(values) {
  const userReport = await handleUserReportAdd(values);
  return userReport;
};

async function updateUserReport(id, values) {
  const userReport = await handleUserReportUpdate(id, values);
  return userReport;
};

async function deleteUserReport(id) {
  const userReport = await handleUserReportDelete(id);
  return userReport;
};
