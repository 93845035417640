const calculationValuePerEmployee = (total, totalEmployees) => {
  const valuePerEmployee = Math.round(total / totalEmployees);
  let values = {
    percentageValue: Math.round((valuePerEmployee / total) * 100),
    value: valuePerEmployee,
  };

  return values;
};

const calcNewValueBaseOnPercentage = (newPercentageValue, valueTot) => {
  return Math.round(valueTot * (newPercentageValue/100));
};

const calcNewPercentageValueBaseOnItemValue = (newItemValue, valueTot) => {
  return Math.round((newItemValue / valueTot) * 100);
};

const calcValuesLimit = (valueTot, valueTotEmployees, newValue, oldValue) => {
  const newValueTot = (valueTotEmployees + newValue - oldValue);
  return (newValueTot > valueTot);
}

export {
  calculationValuePerEmployee,
  calcNewValueBaseOnPercentage,
  calcNewPercentageValueBaseOnItemValue,
  calcValuesLimit
};
