import {
  getUserAllEmployeesGoals,
  getAllEmployeesGoalsByUserIDGoalID,
  handleUserEmployeesGoalsAdd,
  handleUserEmployeesGoalsUpdate,
  handleUserEmployeesGoalsDelete
} from '../helpers';

export const userEmployeesGoalsService = {
  getUserIDAllEmployeesGoals,
  findAllEmployeesGoalsByUserIDGoalID,
  createUserEmployeesGoals,
  updateUserEmployeesGoalsUser,
  deleteUserEmployeesGoalsUser,
};

async function getUserIDAllEmployeesGoals(id, values) {
  const userEmployeesGoals = await getUserAllEmployeesGoals(id, values);
  return userEmployeesGoals;
};

async function findAllEmployeesGoalsByUserIDGoalID(values) {
  const userEmployeesGoals = await getAllEmployeesGoalsByUserIDGoalID(values.userID, values.goalID);
  return userEmployeesGoals;
};

async function createUserEmployeesGoals(values) {
  const userEmployeesGoals = await handleUserEmployeesGoalsAdd(values);
  return userEmployeesGoals;
};

async function updateUserEmployeesGoalsUser(id, values) {
  const userEmployeesGoals = await handleUserEmployeesGoalsUpdate(id, values);
  return userEmployeesGoals;
};

async function deleteUserEmployeesGoalsUser(id) {
  const userEmployeesGoals = await handleUserEmployeesGoalsDelete(id);
  return userEmployeesGoals;
};
