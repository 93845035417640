import { getAllEmployeesUsers, getEmployeeById, getEmployeesActive, handleEmployeeAdd, handleEmployeeUpdate, handleEmployeeDelete } from '../helpers';

export const employeeService = {
  getEmployeesUsers,
  getEmployeeUserById,
  getActiveEmployeeUserById,
  createEmployeeUser,
  updateEmployeeUser,
  deleteEmployeeUser,
};

async function getEmployeesUsers() {
  const usersEmployees = await getAllEmployeesUsers();
  return usersEmployees;
};

async function getActiveEmployeeUserById(id) {
  const employee = await getEmployeesActive(id);
  return employee;
};

async function getEmployeeUserById(id) {
  const employee = await getEmployeeById(id);
  return employee;
};

async function createEmployeeUser(values) {
  const employee = await handleEmployeeAdd(values);
  return employee;
};

async function updateEmployeeUser(id, values) {
  const employee = await handleEmployeeUpdate(id, values);
  return employee;
};

async function deleteEmployeeUser(id) {
  const employee = await handleEmployeeDelete(id);
  return employee;
};
