import React, { useState, useEffect } from 'react';

import { Grid, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import { userService, reportService } from '../../services';
import AppTable from '../../components/Table';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    fontSize: 16,
    padding: '6px 12px',
    width: 200,
    lineHeight: 1.5,
  },
}));

function ListUserReport() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [listReports, setListReports] = useState([]);

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [messages, setMessages] = useState([]);

  const columns = [
    { title: "id", field: "id", hidden: true },
    { title: 'Usuário', field: 'userID', editable: 'onAdd', lookup: { ...listUsers } },
    { title: 'Relatório', field: 'reportID', editable: 'always', lookup: { ...listReports } },
  ];

  const rowAdd = (newData, resolve) => {
    let errorList = []
    if (newData.userID === undefined) {
      errorList.push("Por favor, informe um usuário!");
    }
    if (newData.reportID === undefined) {
      errorList.push("Por favor, informe o relatório!");
    }

    if (errorList.length < 1) {
      reportService.createUserReport(newData)
        .then(res => {
          let dataToAdd = [...tableData];
          dataToAdd.push(newData);
          setTableData([...dataToAdd]);
          setIsError(false);
          setMessages(['Adicionado relatório ao usuário com sucesso!']);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
            setMessages([]);
          }, 5000);
          resolve();
          updateListUsersReports();
        })
        .catch(error => {
          setMessages(['Erro: ' + error.response.data]);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
            setMessages([]);
          }, 5000);
          resolve();
        })
    } else {
      setIsError(true);
      setMessages(['Erro: ' + errorList]);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
      resolve();
    }
  };

  const rowUpdate = (newData, oldData, resolve) => {
    reportService.updateUserReport(newData.id, newData)
      .then(res => {
        const dataUpdate = [...tableData];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setTableData([...dataUpdate]);
        setIsError(false);
        setMessages(['Relatório do usuário atualizado com sucesso!']);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
        resolve();
        updateListUsersReports();
      })
      .catch(error => {
        setIsError(true);
        setMessages(['Erro: ' + error.response.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
        resolve();
      })
  };

  const rowDelete = (oldData, resolve) => {
    reportService.deleteUserReport(oldData.id)
      .then(res => {
        const dataDelete = [...tableData];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setTableData([...dataDelete]);
        setIsError(false);
        setMessages(['Relatório do usuário excluído com sucesso!']);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
        resolve();
        updateListUsersReports();
      })
      .catch(error => {
        setMessages(['Erro: ' + error.response.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
        resolve();
      })
  };

  const updateListUsersReports = () => {
    userService.getUsers()
      .then(res => {
        let listNames = res.data.reduce(function (acc, cur) {
          acc[cur.id] = cur.name;

          return acc;
        }, {});

        setListUsers(listNames);
      })
      .catch(error => {
        setMessages(['Erro: ' + error.response.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      });

    reportService.getReports()
      .then(res => {
        let listReportsNames = res.data.reduce(function (acc, cur) {
          acc[cur.id] = cur.nameReport;

          return acc;
        }, {});

        setListReports(listReportsNames);
      })
      .catch(error => {
        setMessages(['Erro: ' + error.response.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      });

    reportService.getUsersReports()
      .then(res => {
        setTableData(res.data);
      })
      .catch(error => {
        setMessages(['Erro: ' + error.response.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setMessages([]);
    setOpen(false);

    updateListUsersReports();

    setIsLoading(false);
  }, []);

  return (
    <Grid className={classes.root}>
      <Grid item>
        <Typography variant="h5" align="left" color="textPrimary" component="p">
          Administrador - Lista de Relatórios de Usuários
        </Typography>
      </Grid>
      <Grid item>
        <AppTable
          data={tableData}
          columns={columns}
          isLoading={isLoading}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                rowAdd(newData, resolve)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                rowUpdate(newData, oldData, resolve);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                rowDelete(oldData, resolve);
              })
          }}
        />
        <Collapse in={open}>
          {isError ?
            <Alert severity="error">
              {messages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
            :
            <Alert severity="success">
              <AlertTitle>Sucesso</AlertTitle>
              {messages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </Collapse>
      </Grid>
    </Grid>
  );
}

export { ListUserReport };
