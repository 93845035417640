import React, { useState, useEffect } from 'react';

import { Grid, Typography, Collapse, TextField, Select, MenuItem, InputLabel, Paper, Button, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import { userEmployeesGoalsService, employeeService, goalService, accountService, groupService } from '../../services';
import AppTable from '../../components/Table';
import { formatValue, calcNewValueBaseOnPercentage, calcNewPercentageValueBaseOnItemValue, calcValuesLimit } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 300,
      borderColor: 'green',
      borderWidth: 2,
    },
    '& .MuiSelect-root': {
      width: 250,
    },
  },
  button: {
    margin: theme.spacing(1),
    fontSize: 16,
    padding: '6px 12px',
    width: 200,
    lineHeight: 1.5,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
}));

function ListUserEmployeesGoals() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listEmployees, setListEmployees] = useState([]);
  const [listGoals, setListGoals] = useState([]);
  const [listGroups, setListGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [listGoalsOptions, setListGoalsOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const months = ['Nenhum', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const [selectedGoal, setSelectedGoal] = useState('');
  const [datePicked, setDatePicked] = useState('');
  const [valuesTot, setValuesTot] = useState({ percentageValue: 0, value: 0, valueOverLimit: false });
  const [searchValues, setSearchValues] = useState({
    groupId: 0,
    goalId: 0,
    goalValueTotal: 0,
    checkedCopyValueLastMonth: true,
  });
  const [buttonsStatus, setButtonsStatus] = useState({
    disabledCreateGoal: false,
    disabledUpdateGoal: true,
    disabledUpdateEmployeesGoal: true,
  });
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [messages, setMessages] = useState([]);
  const user = accountService.userValue;

  const columns = [
    { title: "id", field: "id", hidden: true },
    { title: 'Usuário', field: 'userID', hidden: true, editable: 'never', defaultSort: "desc" },
    { title: 'Meta', field: 'goalID', lookup: { ...listGoals }, hidden: true, editable: 'never' },
    { title: 'Profissional', field: 'employeeID', lookup: { ...listEmployees }, editable: 'never' },
    { title: 'Data', field: 'dateGoal', type: 'date', dateSetting: 'pt-BR', editable: 'never' },
    { title: '%', field: 'percentageValue', type: 'numeric', editable: 'onUpdate' },
    { title: 'Valor', field: 'value', type: 'numeric', editable: 'onUpdate' },
    { title: 'Ativo', field: 'active', hidden: true },
  ];

  const rowUpdate = (newData, oldData, resolve) => {
    // const valueTotal = formatValue(valueGoalTotal, 'UndoFormat');
    const valueTotal = parseFloat(searchValues.goalValueTotal);
    // const valueTotEmployees = formatValue(valuesTot.value, 'UndoFormat');
    const valueTotEmployees = parseFloat(valuesTot.value);
    // const oldValue = formatValue(oldData.value, 'UndoFormat');
    const oldValue = parseFloat(oldData.value);
    const newPercentageValue = (newData.percentageValue === oldData.percentageValue) && (newData.value !== oldData.value)
      ? calcNewPercentageValueBaseOnItemValue(parseFloat(newData.value), valueTotal)
      : parseFloat(newData.percentageValue);

    const newValue = (newData.percentageValue != oldData.percentageValue)
    ? calcNewValueBaseOnPercentage(parseFloat(newData.percentageValue), valueTotal)
    : parseFloat(newData.value);

    const valueOverLimit = calcValuesLimit(valueTotal, valueTotEmployees, newValue, oldValue);
    (valueOverLimit)
    ? setButtonsStatus({
      disabledCreateGoal: true,
      disabledUpdateGoal: false,
      disabledUpdateEmployeesGoal: true,
    })
    : setButtonsStatus({
      disabledCreateGoal: true,
      disabledUpdateGoal: false,
      disabledUpdateEmployeesGoal: false,
    })

    const newDataValues = {
      id: newData.id,
      userID: newData.userID,
      goalID: newData.goalID,
      employeeID: newData.employeeID,
      dateGoal: newData.dateGoal,
      percentageValue: newPercentageValue,
      value: newValue,
      active: newData.active,
    };
    setValuesTot({
      percentageValue: parseFloat(valuesTot.percentageValue) + newPercentageValue - parseFloat(oldData.percentageValue),
      value: parseFloat(valuesTot.value) + newValue - oldValue,
      valueOverLimit,
    });
    updateTableData(newDataValues, oldData.tableData.id);
    resolve();
  };

  const updateTableData = (newValueItem, itemTableDataId) => {
    const dataUpdate = [...tableData];
    const index = itemTableDataId;
    dataUpdate[index] = newValueItem;
    setTableData([...dataUpdate]);
  }

  const updateListUserEmployeesGoals = (value) => {
    let totPercertage = 0;
    let totValue = 0;
    userEmployeesGoalsService.findAllEmployeesGoalsByUserIDGoalID(value)
      .then(res => {
        const dataTable = res.data.map((goalsEmployees) => {
          totPercertage = totPercertage + parseFloat(goalsEmployees.percentageValue);
          totValue = totValue + parseFloat(goalsEmployees.value);
          return {
            id: goalsEmployees.id,
            userID: goalsEmployees.userID,
            goalID: goalsEmployees.goalID,
            employeeID: goalsEmployees.employeeID,
            dateGoal: formatValue(new Date(goalsEmployees.dateGoal), 'FormatDate'),
            percentageValue: goalsEmployees.percentageValue,
            value: goalsEmployees.value, // formatValue(goalsEmployees.value, 'FormatDecimal'),
            active: goalsEmployees.active,
          }
        });
        setValuesTot({
          ...valuesTot,
          percentageValue: totPercertage, // + '%',
          value: totValue, // formatValue(totValue, 'FormatDecimal'),
        });
        setTableData(dataTable);
      })
      .catch(error => {
        setIsError(true);
        setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      });

    employeeService.getEmployeeUserById(user.id)
      .then(res => {
        let listNames = res.data.reduce(function (acc, cur) {
          acc[cur.id] = cur.name;

          return acc;
        }, {});

        setListEmployees(listNames);
      })
      .catch(error => {
        setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      });
  };

  const loadListGroup = () => {
    groupService.getGroups()
      .then(res => {
        const newListGroup = [{id: 0, name: 'Nenhum'}, ...res.data];
        setListGroups(newListGroup);
        setSelectedGroup(0);
      })
      .catch(error => {
        setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      });
  }

  const loadGoalsEmployeesByDate = (dateGoal) => {
    goalService.getGoalUserByIdDate(user.id, dateGoal)
    .then(res => {
      const dataTable = res.data.filter(item => (item.goalGroupID === selectedGroup));
      setListGoalsOptions(dataTable);
      // const goal = dataTable.filter(goal => (goal.id === event.target.value));

      if (dataTable[0] != undefined) {
        // const goalValue = (dataTable[0].typeValue === 'R$')
        //   ? formatValue(dataTable[0].value, 'FormatDecimal')
        //   : formatValue(dataTable[0].value, 'FormatDecimal');
        setSearchValues({
          ...searchValues,
          groupId: dataTable[0].goalGroupID,
          goalId: dataTable[0].id,
          goalValueTotal: parseFloat(dataTable[0].value),
        });
        updateListUserEmployeesGoals({
          userID: dataTable[0].goalUserID,
          goalID: dataTable[0].id,
         });
        setButtonsStatus({
          disabledCreateGoal: true,
          disabledUpdateGoal: false,
          disabledUpdateEmployeesGoal: false,
        });
      } else {
        setSearchValues({
          ...searchValues,
          groupId: selectedGroup,
          goalId: 0,
          goalValueTotal: 0,
        });
        setButtonsStatus({
          disabledCreateGoal: false,
          disabledUpdateGoal: true,
          disabledUpdateEmployeesGoal: true,
        });
        updateListUserEmployeesGoals({userID: 0, goalID: 0})
      }

      let listUserGoals = res.data.reduce(function (acc, cur) {
        acc[cur.id] = cur.description;

        return acc;
      }, {});

      setListGoals(listUserGoals);
    })
    .catch(error => {
      setIsError(true);
      setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
    });
  }

  const handleChangeGoal = (event) => {
    setSelectedMonth(event.target.value);
    if (event.target.value !== -1) {
      const month = event.target.value < 10 ? '0' + event.target.value : event.target.value;
      const date = new Date().getFullYear() + '-' + month + '-01';
      setDatePicked(date);
      loadGoalsEmployeesByDate(date);
    }
  };

  const handleChangeGroup = (event) => {
    setSelectedMonth(0);
    setButtonsStatus({
      disabledCreateGoal: false,
      disabledUpdateGoal: true,
      disabledUpdateEmployeesGoal: true,
    });
    setTableData([]);
    setValuesTot({ percentageValue: 0, value: 0, valueOverLimit: false });
    setSearchValues({
      ...searchValues,
      groupId: event.target.value,
      goalId: 0,
      goalValueTotal: 0,
    });
    setSelectedGroup(event.target.value);
  };

  const handleChangeValueTotalGoal = (event) => {
    setSearchValues({
      ...searchValues,
      goalValueTotal: event.target.value,
    });
  }

  const createGoal = () => {
    let nameGroup;
    listGroups.map(item => {
      if (item.id === selectedGroup) nameGroup = item.name;
    });

    const newGoal = {
      goalUserID: user.id,
      goalGroupID: selectedGroup,
      dateGoal: new Date(datePicked + ', 03:00:00'),
      description: nameGroup + ' - ' + datePicked,
      typeValue: 'R$',
      value: parseFloat(searchValues.goalValueTotal),
      active: true,
      copyLastMonthPercent: searchValues.checkedCopyValueLastMonth,
    };
    goalService.createGoalUser(newGoal)
    .then(res => {
      loadGoalsEmployeesByDate(datePicked);
    })
    .catch(error => {
      setIsError(true);
      setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
    });
  }

  const handleCreateGoal = () => {
    let errorList = [];
    if (searchValues.groupId === 0) errorList.push("Por favor, informe um grupo!");
    if (datePicked === '') errorList.push("Por favor, informe uma data (Meta)!");
    if (parseFloat(searchValues.goalValueTotal) === 0) errorList.push("Por favor, informe um valor total para a meta!");
    if (errorList.length < 1) {
      createGoal();
      setButtonsStatus({
        disabledCreateGoal: true,
        disabledUpdateGoal: false,
        disabledUpdateEmployeesGoal: false,
      });
    } else {
      setIsError(true);
      setMessages(['Erro: ' + errorList]);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
    }
  }

  const updateGoals = () => {
    const newDataValues = {
      id: searchValues.goalId,
      goalUserID: user.id,
      goalGroupID: searchValues.groupId,
      value: parseFloat(searchValues.goalValueTotal),
    };
    goalService.updateGoalUser(searchValues.goalId, newDataValues)
    .then(res => {
      setIsError(false);
        setMessages(['Valor total da meta atualizada com sucesso!']);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      loadGoalsEmployeesByDate(datePicked);
    })
    .catch(error => {
      setIsError(true);
      setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
    });
  }

  const handleUpdateGoal = () => {
    let errorList = [];
    if (parseFloat(searchValues.goalValueTotal) === 0) errorList.push("Por favor, informe um valor total para a meta!");
    if (errorList.length < 1) {
      updateGoals();
      setButtonsStatus({
        disabledCreateGoal: true,
        disabledUpdateGoal: false,
        disabledUpdateEmployeesGoal: false,
      });
    } else {
      setIsError(true);
      setMessages(['Erro: ' + errorList]);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
    }
  }

  const updateEmployeesGoals = () => {
    if (!valuesTot.valueOverLimit) {
      if (parseFloat(valuesTot.percentageValue) === 100) {
        tableData.every(item => {
          const newDataValues = {
            id: item.id,
            userID: item.userID,
            goalID: item.goalID,
            employeeID: item.employeeID,
            dateGoal: Date(item.dateGoal),
            percentageValue: parseFloat(item.percentageValue),
            value: parseFloat(item.value),
            active: item.active,
          };
          userEmployeesGoalsService.updateUserEmployeesGoalsUser(newDataValues.id, newDataValues)
            .catch(error => {
              setMessages(['Erro: ' + error + ' / ' + error.response?.data]);
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
                setMessages([]);
              }, 5000);
              return false;
            })
          return true;
        })
        setButtonsStatus({
          disabledCreateGoal: true,
          disabledUpdateGoal: false,
          disabledUpdateEmployeesGoal: true,
        });
        setIsError(false);
        setMessages(['Metas dos profissionais atualizadas com sucesso!']);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      } else {
        setIsError(true);
        setMessages(['Erro: Valor informado não atingiu 100% da meta!']);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setMessages([]);
        }, 5000);
      }
    } else {
      setIsError(true);
      setMessages(['Erro: Valor informado ultrapassou o limite total da meta!']);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessages([]);
      }, 5000);
    }
  }

  const handleUpdateEmployeesGoals = () => {
    updateEmployeesGoals();
  }

  const handleChange = (event) => {
    setSearchValues({ ...searchValues, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setMessages([]);
    setOpen(false);
    loadListGroup();
    setIsLoading(false);
  }, []);

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" align="left" color="textPrimary" component="p">
          Lista de Metas dos Profissionais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <InputLabel id="select-groups-label">Grupos</InputLabel>
          <Select
            labelId="select-groups-filled-label"
            id="select-filled"
            label="Grupos"
            variant="outlined"
            margin="normal"
            value={selectedGroup}
            onChange={handleChangeGroup}
          >
            {listGroups.map((groupItem) => (
              <MenuItem key={groupItem.id} value={groupItem.id}>{groupItem.name}</MenuItem>
            ))}
          </Select>
          <InputLabel id="select-goals-label">Metas</InputLabel>
          <Select
            labelId="select-goals-label"
            id="select-filled"
            label="Metas"
            variant="outlined"
            margin="normal"
            value={selectedMonth}
            onChange={handleChangeGoal}
          >
            {months.map((month, index, array) => (
              <MenuItem key={index} value={index}>{month}</MenuItem>
            ))}
          </Select>
          <InputLabel id="goal-value-total-label">Meta Valor Total</InputLabel>
          <TextField
            id="value-goal-total"
            margin="normal"
            label="Meta valor total"
            variant="outlined"
            value={searchValues.goalValueTotal}
            type="number"
            onChange={handleChangeValueTotalGoal}
            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
          />
          <Grid container justify="flex-start">
            {buttonsStatus.disabledUpdateGoal
              ?
              <Grid container justify="flex-start">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                    <Checkbox
                      checked={searchValues.checkedCopyValueLastMonth}
                      onChange={handleChange}
                      name="checkedCopyValueLastMonth"
                      color="primary"
                    />
                    }
                    label="Copiar parcentuais do mês anterior"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    className={classes.button}
                    onClick={handleCreateGoal}
                  >Criar Meta</Button>
                </Grid>
              </Grid>
              :
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={classes.button}
                  disabled={buttonsStatus.disabledUpdateGoal}
                  onClick={handleUpdateGoal}
                >Atualizar Total</Button>
            }
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open}>
          {isError ?
            <Alert severity="error">
              {messages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
            :
            <Alert severity="success">
              <AlertTitle>Sucesso</AlertTitle>
              {messages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <Grid item xs={4}>
            <TextField
              disabled
              id="percentage-value-total"
              margin="normal"
              label="Meta percentual total dos profissionais"
              variant="standard"
              type="number"
              error={valuesTot.valueOverLimit}
              value={valuesTot.percentageValue}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled
              id="value-total"
              margin="normal"
              label="Meta valor total dos profissionais"
              variant="standard"
              type="number"
              error={valuesTot.valueOverLimit}
              value={valuesTot.value}
              startAdornment={<InputAdornment position="start">R$</InputAdornment>}
            />
          </Grid>
          <Grid container justify="flex-start">
            <Button
              type="submit"
              color="primary"
              size="medium"
              variant="contained"
              className={classes.button}
              disabled={buttonsStatus.disabledUpdateEmployeesGoal}
              onClick={handleUpdateEmployeesGoals}
            >Salvar Metas</Button>
            <Grid container justify="flex-start">
              <InputLabel id="reminder-save-goal-button">*Necessário salvar as metas para aplicar os valores</InputLabel>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AppTable
              data={tableData}
              columns={columns}
              isLoading={isLoading}
              pageSize={10}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    rowUpdate(newData, oldData, resolve);
                  }),
              }}
            />
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
}

export { ListUserEmployeesGoals };
