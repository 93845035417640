import axios from 'axios';

export {
  handleLogin,
  handleForgotPassword,
  handleResetPassword,
  handlePassword,
  handleUserAdd,
  handleUserUpdate,
  handleUserDelete,
  handleGroupAdd,
  handleGroupUpdate,
  handleGroupDelete,
  handleGoalAdd,
  handleGoalUpdate,
  handleGoalDelete,
  getAllUsers,
  getAllGoalsUsers,
  getAllGroups,
  getGoalById,
  getGoalByIdDate,
  getToken,
  getEmbedToken,
  getAllReports,
  getReportById,
  handleReportAdd,
  handleReportUpdate,
  handleReportDelete,
  getUserReportById,
  getAllUsersReports,
  handleUserReportAdd,
  handleUserReportUpdate,
  handleUserReportDelete,
  getAllEmployeesUsers,
  getEmployeeById,
  getEmployeesActive,
  handleEmployeeAdd,
  handleEmployeeUpdate,
  handleEmployeeDelete,
  getUserAllEmployeesGoals,
  getAllEmployeesGoalsByUserIDGoalID,
  handleUserEmployeesGoalsAdd,
  handleUserEmployeesGoalsUpdate,
  handleUserEmployeesGoalsDelete,
};

const axiosAPI = axios.create({
  // baseURL: "http://localhost:3000/api/v1"
  // kinghost
  baseURL: "https://belezainteligente.app/api/v1",
  // umbler
  //baseURL: "http://belezainteligente-app.umbler.net/api/v1"
});

// Account
const getToken = () => ({
  Authorization: `Bearer ${localStorage.getItem("appToken")}`
})

const handleLogin = async (values) => {
  const { data } = await axiosAPI.post('/authenticate', values)
  return data
};

const handleForgotPassword = async (email) => {
  const data = await axiosAPI.post('/forgotpassword', { email });
  return data;
};

async function handleResetPassword(token, values) {
  const data = await axiosAPI.post(`/resetpassword/${token}`, values);
  return data;
};

// Users
const getAllUsers = async () => {
  const headers = getToken();
  const data = await axiosAPI.get(`/admin/users`, { headers });
  return data;
};

const handleUserAdd = async (values) => {
  const headers = getToken();

  const data = await axiosAPI.post(`/admin/users/add`, values, { headers });
  return data;
};

const handleUserUpdate = async (userID, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/admin/users/${userID}`, values, { headers });
  return data;
};

const handleUserDelete = async (userID) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/users/${userID}`, { headers });
  return data;
};

const handlePassword = async (userID, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/user/profile/${userID}`, values, { headers });
  return data;
};

// Groups
const getAllGroups = async () => {
  const headers = getToken();
  const data = await axiosAPI.get(`/groups`, { headers });
  return data;
};

const handleGroupAdd = async (values) => {
  const headers = getToken();

  const data = await axiosAPI.post(`/admin/groups/add`, values, { headers });
  return data;
};

const handleGroupUpdate = async (groupID, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/admin/groups/${groupID}`, values, { headers });
  return data;
};

const handleGroupDelete = async (groupID) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/groups/${groupID}`, { headers });
  return data;
};

// Goals
const getAllGoalsUsers = async () => {
  const headers = getToken();
  const data = await axiosAPI.get(`/admin/goals`, { headers });
  return data;
};

const getGoalById = async userID => {
  const headers = getToken();
  const data = await axiosAPI.get(`/goals/${userID}`, { headers });
  return data;
};

const getGoalByIdDate = async (userID, dateGoal) => {
  const headers = getToken();
  const data = await axiosAPI.get(`/goals/${userID}/${dateGoal}`, { headers });
  return data;
};

const handleGoalAdd = async (values) => {
  const headers = getToken();
  const data = await axiosAPI.post(`/goals/add`, values, { headers });
  return data;
};

const handleGoalUpdate = async (goalID, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/goals/${goalID}`, values, { headers });
  return data;
};

const handleGoalDelete = async (goalID) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/goals/${goalID}`, { headers });
  return data;
};

// PowerBI Token
const getEmbedToken = async (groupID, reportID) => {
  const headers = getToken();
  const data = await axiosAPI.get(`/report/${groupID}/${reportID}`, { headers });
  return data;
};

// Reports
const getAllReports = async () => {
  const headers = getToken();
  const data = await axiosAPI.get(`/admin/reportsBI`, { headers });
  return data;
};

const getReportById = async (reportID) => {
  const headers = getToken();
  const data = await axiosAPI.get(`/reportsBI/${reportID}`, { headers });
  return data;
};

const handleReportAdd = async (values) => {
  const headers = getToken();
  const data = await axiosAPI.post(`/admin/reportsBI/add`, values, { headers });
  return data;
};

const handleReportUpdate = async (reportID, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/admin/reportsBI/${reportID}`, values, { headers });
  return data;
};

const handleReportDelete = async (reportID) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/reportsBI/${reportID}`, { headers });
  return data;
};

// UserReport
const getAllUsersReports = async () => {
  const headers = getToken();
  const data = await axiosAPI.get(`/admin/userReportBI`, { headers });
  return data;
};

const getUserReportById = async userID => {
  const headers = getToken();
  const data = await axiosAPI.get(`/userReportBI/${userID}`, { headers });
  return data;
};

const handleUserReportAdd = async (values) => {
  const headers = getToken();
  const data = await axiosAPI.post(`/admin/userReportBI/add`, values, { headers });
  return data;
};

const handleUserReportUpdate = async (id, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/admin/userReportBI/${id}`, values, { headers });
  return data;
};

const handleUserReportDelete = async (id) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/userReportBI/${id}`, { headers });
  return data;
};

// Employees
const getAllEmployeesUsers = async () => {
  const headers = getToken();
  const data = await axiosAPI.get(`/admin/employees`, { headers });
  return data;
};

const getEmployeeById = async userID => {
  const headers = getToken();
  const data = await axiosAPI.get(`/employees/${userID}`, { headers });
  return data;
};

const getEmployeesActive = async userID => {
  const headers = getToken();
  const data = await axiosAPI.get(`/employees/active/${userID}`, { headers });
  return data;
}

const handleEmployeeAdd = async (values) => {
  const headers = getToken();
  const data = await axiosAPI.post(`/admin/employees/add`, values, { headers });
  return data;
};

const handleEmployeeUpdate = async (employeeID, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/employees/upd/${employeeID}`, values, { headers });
  return data;
};

const handleEmployeeDelete = async (employeeID) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/employees/${employeeID}`, { headers });
  return data;
};

// UserEmployeesGoals
const getUserAllEmployeesGoals = async (userID) => {
  const headers = getToken();
  const data = await axiosAPI.get(`/userEmployeesGoals/${userID}`, { headers });
  return data;
};

// UserEmployeesGoals where userID and goalID
const getAllEmployeesGoalsByUserIDGoalID = async (userID, goalID) => {
  const headers = getToken();
  const data = await axiosAPI.get(`/userEmployeesGoals/find/${userID}/${goalID}`, { headers });
  return data;
};

const handleUserEmployeesGoalsAdd = async (values) => {
  const headers = getToken();
  const data = await axiosAPI.post(`/userEmployeesGoals/add`, values, { headers });
  return data;
};

const handleUserEmployeesGoalsUpdate = async (id, values) => {
  const headers = getToken();
  const data = await axiosAPI.put(`/userEmployeesGoals/upd/${id}`, values, { headers });
  return data;
};

const handleUserEmployeesGoalsDelete = async (id) => {
  const headers = getToken();
  const data = await axiosAPI.delete(`/admin/userEmployeesGoals/${id}`, { headers });
  return data;
};
